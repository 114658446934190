import React from 'react';

import { cn } from 'helpers/classnames';
import MartyLink from 'components/common/MartyLink';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/common/breadcrumbs.scss';

interface Link {
  text: string;
  to: string;
  [key: string]: any;
}

interface Props {
  className?: string;
  currentText: string;
  links: Link[];
  dataMask?: boolean;
  hideOnMobile?: boolean;
}

const Breadcrumbs = ({ links, currentText, className, dataMask, hideOnMobile }: Props) => {
  const { testId } = useMartyContext();
  if (!links || links.length === 0) {
    return null; // No reason to render breadcrumbs with no links
  } else {
    const lastLink = links[links.length - 1]!.to;
    return (
      <nav aria-label="breadcrumb">
        <ol className={cn(css.breadcrumb, { [css.hideOnMobile]: hideOnMobile }, className)}>
          <li>
            <MartyLink to={lastLink} data-test-id={testId('backBreadcrumb')}>
              Back
            </MartyLink>
          </li>
          {links.map(({ text, ...rest }, index) => (
            <li key={index} className={css.breadcrumbItem} data-cs-mask={dataMask}>
              <MartyLink data-test-id={testId('breadcrumb')} {...rest}>
                {text}
              </MartyLink>
            </li>
          ))}
          <li className={css.breadcrumbItem} aria-current="page">
            <span data-test-id={testId('currentBreadcrumb')}>{currentText}</span>
          </li>
        </ol>
      </nav>
    );
  }
};

export default Breadcrumbs;
