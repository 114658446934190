import type { ThunkAction } from 'redux-thunk';

import type { AppState } from 'types/app';
import {
  CANCEL_ORDER_HISTORY_SEARCH,
  CLEAR_ORDERS,
  ERROR_ORDER_HISTORY_SEARCH,
  RECEIVE_ORDER_HISTORY_SEARCH,
  RECEIVE_ORDERS,
  REQUEST_ORDER_HISTORY_SEARCH,
  REQUEST_ORDERS,
  TOGGLE_ORDER_COLLAPSE
} from 'constants/reduxActions';
import { prependAppRoot } from 'history/AppRootUtils';
import { authenticationErrorCatchHandlerFactory, fetchOrderHistorySearchCatchHandler } from 'actions/errors';
import { fetchApiNotAuthenticatedMiddleware, fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { getOrdersBySearch, getOrdersV3 } from 'apis/mafia';
import type { OrderQueryParams, OrdersResponse } from 'types/mafia';
import { setSessionCookies } from 'actions/session';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { selectMafiaConfig } from 'selectors/environment';
import { returnToAccountPath } from 'constants/appConstants';

export type OrdersAction =
  | ReturnType<typeof requestOrders>
  | ReturnType<typeof receiveOrders>
  | ReturnType<typeof cancelOrderHistorySearch>
  | ReturnType<typeof errorOrderHistorySearch>
  | ReturnType<typeof requestOrderHistorySearch>
  | ReturnType<typeof receiveOrderHistorySearch>
  | ReturnType<typeof toggleOrderCollapse>
  | ReturnType<typeof clearOrdersState>;

export function requestOrders() {
  return {
    type: REQUEST_ORDERS
  } as const;
}

export function receiveOrders(resp: OrdersResponse) {
  const { orders, totalPages } = resp;
  return {
    type: RECEIVE_ORDERS,
    orders: orders || [],
    totalPages
  } as const;
}

export function requestOrderHistorySearch() {
  return {
    type: REQUEST_ORDER_HISTORY_SEARCH
  } as const;
}

export function receiveOrderHistorySearch(resp: OrdersResponse) {
  const { orders, totalPages } = resp;
  return {
    type: RECEIVE_ORDER_HISTORY_SEARCH,
    orders: orders || [],
    totalPages
  } as const;
}

export function cancelOrderHistorySearch() {
  return {
    type: CANCEL_ORDER_HISTORY_SEARCH
  } as const;
}

export function errorOrderHistorySearch() {
  return {
    type: ERROR_ORDER_HISTORY_SEARCH
  } as const;
}

export function toggleOrderCollapse(orderIdToCollapse: string) {
  return {
    type: TOGGLE_ORDER_COLLAPSE,
    orderIdToCollapse
  } as const;
}

export function clearOrdersState() {
  return {
    type: CLEAR_ORDERS
  } as const;
}

export interface FetchOrdersOptions {
  returnTo?: string;
  failSilently?: boolean;
  isAccumulatingOrders?: boolean;
  isReturnsEligibleFetch?: boolean;
}

export function fetchOrderHistorySearch(
  queryParams: Partial<OrderQueryParams>,
  { returnTo = returnToAccountPath }
): ThunkAction<void, AppState, void, OrdersAction> {
  return (dispatch, getState) => {
    dispatch(requestOrderHistorySearch());

    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getOrdersBySearch(mafiaConfig, queryParams, cookies)
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then(resp => {
        if (queryParams.isAccumulatingOrders) {
          const orders = state.orders.orders?.concat(resp.orders);
          dispatch(receiveOrderHistorySearch({ orders: orders ?? [], totalPages: resp.totalPages }));
        } else {
          dispatch(receiveOrderHistorySearch(resp));
        }
      })
      .catch(fetchOrderHistorySearchCatchHandler(dispatch, prependAppRoot(returnTo, location), errorOrderHistorySearch));
  };
}

export function fetchOrders(
  queryParams: Partial<OrderQueryParams>,
  { returnTo = returnToAccountPath, failSilently = false }: FetchOrdersOptions = {}
): ThunkAction<void, AppState, void, OrdersAction> {
  return (dispatch, getState) => {
    dispatch(requestOrders());

    const state = getState();
    const {
      cookies,
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);
    return (
      getOrdersV3(mafiaConfig, queryParams, cookies)
        .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
        .then(fetchApiNotAuthenticatedMiddleware)
        .then(fetchErrorMiddleware)
        .then(resp => {
          if (queryParams.isAccumulatingOrders) {
            const orders = state.orders.orders?.concat(resp.orders);
            dispatch(receiveOrders({ orders: orders ?? [], totalPages: resp.totalPages }));
          } else {
            dispatch(receiveOrders(resp));
          }
        })
        // just ignore any error thrown above if silent fail flag passed
        .catch(e =>
          !failSilently
            ? authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot(returnTo, location))(e)
            : dispatch(receiveOrders({ orders: [], totalPages: 0 }))
        )
    );
  };
}
